import { post, get } from '@/utils/request';

// 获取旧料清单
const recycleList = (data) => post({
    url: '/store/goods.Recycle/recycleList',
    data: data
});

// 旧料统计
const getRecycleTotal = (data) => post({
    url: '/store/goods.Recycle/getRecycleTotal',
    data: data
})

// 旧料清单详情
const getRecycleGoodsDetails = (data) => post({
    url: '/store/goods.Recycle/getRecycleGoodsDetails',
    data: data
})

// 旧料清单导出
const exportList = (data) => post({
    url: '/store/goods.Recycle/recycleList',
    data: data
})

// 旧料单打印
const getRecycleGoodsPrintReq = (cond) => post({
    url: '/store/goods.Recycle/recycleGoodsPrint',
    data: {
        ...cond
    },
});

export {
    getRecycleGoodsPrintReq,
    recycleList,
    getRecycleTotal,
    getRecycleGoodsDetails,
    exportList
}